(function($) {

/*
*  new_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/

function wm_location_error(error = null) {
    if (error) {
        console.log(error);
    }

    console.log('Failed to get location');
    $('[data-search-error]').slideToggle('fast');
}


function wm_get_location(map) {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
        console.log('geo1');
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log('geo2');
            console.log(position.coords);
            map.setZoom(6);
            map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude } );
        }, wm_location_error);
    } else {
        // Browser doesn't support Geolocation
        wm_location_error();
    }
}

function hide_all_infowindows(map) {
    map.markers.forEach(function (marker) {
        marker.infowindow.close(map, marker);
        marker.clicked = false;
    });
}

function new_map( $el ) {

	// var
	var $markers = $el.find('.marker');


	// vars
    var args = {
        zoom		: 8,
        // center     : new google.maps.LatLng(51.91943, 19.145136),
        mapTypeId	: google.maps.MapTypeId.ROADMAP,
        // styles      : [
        //     {
        //         "featureType": "water",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#e9e9e9"
        //             },
        //             {
        //                 "lightness": 17
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "landscape",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#f5f5f5"
        //             },
        //             {
        //                 "lightness": 20
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "lightness": 17
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.highway",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "lightness": 29
        //             },
        //             {
        //                 "weight": 0.2
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.arterial",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "lightness": 18
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "road.local",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "lightness": 16
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "poi",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#f5f5f5"
        //             },
        //             {
        //                 "lightness": 21
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "poi.park",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#dedede"
        //             },
        //             {
        //                 "lightness": 21
        //             }
        //         ]
        //     },
        //     {
        //         "elementType": "labels.text.stroke",
        //         "stylers": [
        //             {
        //                 "visibility": "on"
        //             },
        //             {
        //                 "color": "#ffffff"
        //             },
        //             {
        //                 "lightness": 16
        //             }
        //         ]
        //     },
        //     {
        //         "elementType": "labels.text.fill",
        //         "stylers": [
        //             {
        //                 "saturation": 36
        //             },
        //             {
        //                 "color": "#333333"
        //             },
        //             {
        //                 "lightness": 40
        //             }
        //         ]
        //     },
        //     {
        //         "elementType": "labels.icon",
        //         "stylers": [
        //             {
        //                 "visibility": "off"
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "transit",
        //         "elementType": "geometry",
        //         "stylers": [
        //             {
        //                 "color": "#f2f2f2"
        //             },
        //             {
        //                 "lightness": 19
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative",
        //         "elementType": "geometry.fill",
        //         "stylers": [
        //             {
        //                 "color": "#fefefe"
        //             },
        //             {
        //                 "lightness": 20
        //             }
        //         ]
        //     },
        //     {
        //         "featureType": "administrative",
        //         "elementType": "geometry.stroke",
        //         "stylers": [
        //             {
        //                 "color": "#fefefe"
        //             },
        //             {
        //                 "lightness": 17
        //             },
        //             {
        //                 "weight": 1.2
        //             }
        //         ]
        //     }
        // ],
        scrollwheel : false,
    };


	// create map
	var map = new google.maps.Map( $el[0], args);


	// add a markers reference
	map.markers = [];


	// add markers
	$markers.each(function(){

	add_marker( $(this), map );

	});


	// center map
	center_map( map );


	// return
	return map;

}

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/

function add_marker( $marker, map ) {
    console.log('add_marker');

	// var
	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
	});

    var icon_url = $marker.attr('data-icon') || 'marker';

    var icon = { url: acfmaps.imgdir + icon_url + ".svg" };
    marker.setIcon(icon);

	// if marker contains HTML, add it to an infoWindow
	if( $marker.html() )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		infowindow.addListener('closeclick', function() {
            marker.clicked = false;
		});

		// show info window when marker is clicked
		marker.addListener('click', function() {

            hide_all_infowindows(map);
			infowindow.open( map, marker );
            marker.clicked = true;

		});

		marker.addListener('mouseover', function() {

			infowindow.open( map, marker );

		});

		marker.addListener('mouseout', function() {
            if (marker.clicked != true) {
                setTimeout(() => infowindow.close(), 1000);
            }
		});

        marker.infowindow = infowindow;

	}

    // add to array
    map.markers.push( marker );

}

function add_marker_ajax( marker_data, map ) {
    console.log('add_marker_ajax');

	// var
	var latlng = new google.maps.LatLng( marker_data.lat, marker_data.lng );

	// create marker
	var marker = new google.maps.Marker({
		position	: latlng,
		map			: map,
	});

    var icon_url = marker_data.icon || 'marker';

    var icon = {
        url: acfmaps.imgdir + icon_url + ".svg",
    };
    marker.setIcon(icon);

	// if marker contains HTML, add it to an infoWindow
	if( marker_data.content )
	{
		// create info window
		var infowindow = new google.maps.InfoWindow({
			content		: marker_data.content
		});

		infowindow.addListener('closeclick', function() {
            marker.clicked = false;
		});

		// show info window when marker is clicked
		marker.addListener('click', function() {

            hide_all_infowindows(map);
			infowindow.open( map, marker );
            marker.clicked = true;

		});

		marker.addListener('mouseover', function() {

			infowindow.open( map, marker );

		});

		marker.addListener('mouseout', function() {
            if (marker.clicked != true) {
                setTimeout(() => infowindow.close(), 1000);
            }
		});

        marker.infowindow = infowindow;

	}

    // add to array
    map.markers.push( marker );

}

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/

function center_map( map ) {

	// vars
	var bounds = new google.maps.LatLngBounds();

	// loop through all markers and create bounds
	$.each( map.markers, function( i, marker ){

		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

		bounds.extend( latlng );

	});

	// only 1 marker?
	if ( map.markers.length == 1 ) {
        // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 10 );
	} else {
		// fit to bounds
		map.fitBounds( bounds );
	}

}


function delete_markers(map) {
    for (var i = 0; i < map.markers.length; i++) {
        map.markers[i].setMap(null);
    }
    map.markers = [];
}


function update_markers() {
    console.log('update markers');

    var ajax_data = {
        action: "map_markers",
        search_text: search_text,
    };

    $.ajax({
        type: "POST",
        dataType: "json",
        url: acfmaps.ajaxurl,
        data: ajax_data,
        success: function (data) {
            delete_markers(map);

            $('[data-search-feedback]').hide();

            for (var i = 0; i < data.length; i++) {
                add_marker_ajax(data[i], map);
            }

            if (data.length == 0) {
                $('[data-search-feedback]').removeClass("success").addClass("warning").html(acfmaps.msg_notfound).slideToggle();
            } else {
                $('[data-search-feedback]').removeClass("warning").addClass("success").html(acfmaps.msg_found.format(data.length)).slideToggle();
                center_map(map);
            }
        }

    });
}

function get_ajax_results() {
    var ajax_data = {
        action: "get_results",
        search_text: search_text,
    };

    $.ajax({
        type: "POST",
        dataType: "json",
        url: acfmaps.ajaxurl,
        data: ajax_data,
        success: function (response) {
            var data = response.data || '';

            $('[data-search-results]').html(data);

            if (data) {
                var elem = new Foundation.Accordion($('[data-search-results] .map__items'));

                let $results = $('[data-search-results] .map__item');

                if ($results.length == 1) {
                    $results.eq(0).find('.map__item__title').trigger('click');
                }
            }
        }

    });
}

function search_markers() {
    console.log('search_markers');
    search_text = $('[data-search-locality]').val();
    update_markers();

    if ($('[data-search-results]')) {
        get_ajax_results();
    }
}


/*
*  document ready
*
*  This function will render each map when the document is ready (page has loaded)
*
*  @type	function
*  @date	8/11/2013
*  @since	5.0.0
*
*  @param	n/a
*  @return	n/a
*/
// global var
var map = null;
var search_text = null;

$(document).ready(function(){

    String.prototype.format = function() {
        var formatted = this;
        for(arg in arguments) {
            formatted = formatted.replace("{" + arg + "}", arguments[arg]);
        }
        return formatted;
    };

    $('.google-map').each(function(){
        // create map
        map = new_map( $(this) );

        if (typeof(MarkerClusterer) != 'undefined') {
            var markerCluster = new MarkerClusterer(map, map.markers,
                {
                    imagePath: acfmaps.imgdir + 'marker',
                    imageExtension: 'svg',
                });
        }
    });


    $('[data-search-locality]').keypress(function (event) {
        console.log('search');
        if (event.which === 13) {
            search_text = $(this).val();
            search_markers();
        }
    });

    $('[data-search-submit]').click(function (event) {
        console.log('submit');
        event.preventDefault();
        search_markers();
    });

    $('[data-search-geo]').click(function(event) {
        event.preventDefault();
        wm_get_location(map);
    });

});

})(jQuery);
